.accordion-button:focus {
    z-index: 0 !important;
    border-color: none !important;
    outline: 0;
    box-shadow: none !important;
}

.accordion-button {
    font-size: 18px !important;
    font-weight: 600 !important;
}

.highlight-row {
    background-color: #FADA5E;
    padding: 10px  !important;
    align-items: center!important;
    justify-content: center!important;
    text-align: center;
}

.jOMIlp {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.paddrow {
    padding: 10px !important;
    align-items: center!important;
    justify-content: center!important;
    text-align: center;
}
.ilzOLx{
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper ,.css-pwxzbm{
    position: absolute !important;
    content: "" !important;
    min-height: max-content !important;
    max-height: 250px !important;
    width: 100px!important;
    bottom: 10% !important;
}
.btn-sq{
    background-color: #43668E;
    border: 1px solid #43668E;
    font-size: 12px;
    color: white;
    width: 95.5px;
    height: 35px;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 20px;
}
.css-q8hpuo-MuiFormControl-root{
    width: 100% !important;
}
.css-q8hpuo-MuiFormControl-root1{
    width: 100% !important;
}
.w-all{
    width: 170px!important;
}
.w1-all{
    width: 215px!important;
}