.text_area {
    width: 100%;
    border: none;
    outline: 0;
}

.overSize {
    height: 265px;
    overflow: auto;
}

/* .overSize::-webkit-scrollbar {
    width: 10px;
} */
.recharts-text {
    font-size: smaller !important;
    margin-right: 15px !important;
}

.subText p {
    font-size: 12px !important;
}

#cattleReg .animal_dark_bg {
    line-height: 18px !important;
}

#cattleReg .animal_dark_bg_text p {
    font-size: 10px !important;
}

#cattleReg .accordion-button {
    font-size: 12px !important;
    font-weight: 300 !important;
}

.tableBreed {
    height: 360px;
}

.tableBreed1 {
    height: 480px;
}

#breedFixTable .bxUKzH {
    min-height: 280px !important;
    /* overflow-x:scroll; */
}

#aiTable .dSZDRP {
    min-height: 271px !important;
}

#aiTable {
    height: 271px !important;
}

#breed .btn-blue,
#breed .btn-yellow,
#breed .btn-perot {
    min-width: 107% !important;
    font-size: 12px !important;
    text-shadow: 1px 2px 3px #000000;
}

.recharts-default-legend {
    bottom: -50px !important;
}

#chartLine [class="recharts-legend-wrapper"] {
    width: auto !important;
    bottom: -13px !important;
}

b {
    font-size: 10px;
}

.increase_text p {
    font-size: 10px !important;
}

.colmBreed {
    font-size: 12px !important;
}

.colmBreed .blankColumns {
    border: 0.10px solid grey !important;
    white-space: nowrap !important;
}

#aiSave .btn-blue-Animal {
    background-color: #FFFFFF;
    color: #000000;
}

.activeBreed {
    background-color: #43668E !important;
    color: #FFFFFF !important;
}

.idNo {
    font-size: 0.8rem !important;
}

.tableBorder {
    margin-top: 0 !important;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    height: 270px;
    border-radius: 6px;
    overflow-x: auto !important;
}

.NoTableBorder {
    margin-top: 0 !important;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 0px;
    overflow-y: scroll !important;
    height: 80vh;
}

.tableBorder1 {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    height: 270px;
    border-radius: 6px;
    overflow-y : scroll !important;
}

.tableBorder2 {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    height: 350px;
    border-radius: 6px;
    overflow-x: auto !important;
}

.DataTableBorder {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    height: 85vh;
    border-radius: 0px;
    padding: 0 !important;
}

.noData {
    font-size: 18px !important;
}


@media screen and (min-width:1400px) and (max-width:1580px) {

    #breed .btn-blue,
    #breed .btn-yellow,
    #breed .btn-perot {
        min-width: 107% !important;
        font-size: 9px !important;
        text-shadow: 1px 2px 3px #000000;
    }
}

.idNo input {
    border: none !important;
}


#breed1 .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    min-width: 370px !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    font-size: 12px !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
    color: #000000 !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutlin {
    border: none !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none !important;
    border-bottom: 1px solid #000000 !important;
    border-radius: 0 !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
    left: -9px !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 11.5px 4px !important;
}

#breed1 .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    min-width: 90% !important
}

.css-1bn53lx {
    min-width: 370px !important;
}

.css-1ald77x.Mui-error {
    color: #000000 !important;
}

.rightArrow {
    transition: .5s;
}

.rightArrow:hover {
    margin-left: 5px;
    transition: 0.5s;
}