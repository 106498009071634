.rightTable {
    border: 2px solid #43668E;
    border-radius: 5px;
}

.boxBg {
    background-color: #ECECEC;
}

.boxBg1 {
    background-color: #F1F1F1;
}

.cardHeading h5 {
    height: 20px;
    padding: 5px 0;
}

.mainCard {
    background-color: #FAFAFA;
    height: 79vh;
}

.boxBg h6 {
    font-size: 12px;
}

.data-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - (/* Add your heightOffSet value here */px + 120px));
    text-align: center;
    font-size: 18px;
    color: gray;
}

.no-data-message {
    text-align: center;
    font-size: 18px;
    color: gray;
}