
.css-1pduc5x-MuiStack-root>.MuiTextField-root {
    min-width: 365px !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    font-size: 12px !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
    color: #000000 !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutlin {
    border: none !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none !important;
    border-bottom: 1px solid #000000 !important;
    border-radius: 0 !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
    left: -9px !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 11.5px 4px !important;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    left: -5px !important;
    font-size: 14px !important;
}