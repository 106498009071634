@media screen and (min-width:1200px) and (max-width: 1400px) {
    .animal-data p {
        margin-left: 14px !important;
    }

    .recharts-wrapper {
        height: 137.667px !important;
    }
}

@media screen and (min-width:1400px) and (max-width: 1500px) {
    .animalIcon .ms-5 {
        margin-left: 2rem !important;
    }
}

.chartlable {
    font-size: 10px !important;
}

#chartLine .recharts-text {
    font-size: 12px !important;
}

.recharts-legend-wrapper {
    padding-left: 15px;
}

.animal_border {
    height: 70px !important;
}

.max-width {
    max-width: 100% !important;
    overflow-x: hidden !important;
}

.canvasjs-chart-credit {
    display: none !important;
}
/* 
.pieChart .canvasjs-chart-canvas {
    width: 100% !important;
} */
.apexcharts-legend{
    text-align: justify !important;
}
.recharts-legend-icon{
    display: none !important;
}
.btn-pinkre{
    background-color: #95B9C7;
}