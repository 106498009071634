.backgroundBG {
    background-color: #FFFFFF;
    box-shadow: 1px 1px 2px;
    border-radius: 2px;
}

.backgroundBG h5 {
    font-size: 14px !important;
    font-weight: 600;
    margin-bottom: 0 !important;
}

.backgroundBG p {
    font-size: 13px !important;
    margin: 0 !important;
}

.icon svg {
    font-size: 25px;
}

.icon p {
    font-size: 10px !important;
}

.backgroundBG .subProfile {
    overflow: auto !important;
}

.backgroundBG .dDatatype {
    color: rgba(228, 18, 12, 0.74);
    font-size: 12px;
    font-weight: bold;
    width: 80px;
    text-align: center;
}

.backgroundBG .dCom {
    color: #60D460 !important;
    font-size: 12px;
    width: 80px;
    text-align: center;
}
.backgroundBG .dComBold{
    color: #60D460 !important;
    font-size: 12px;
    font-weight: bold;
    width: 80px;
    text-align: center;
}

.backgroundBG .ct {
    font-size: 12px !important;
    text-align: center;
    width: 80px;
}

.backgroundBG .dName {
    font-size: 10px;
    color: #00acc1;
    min-height: 30px;
    text-align: center;
}

.backgroundBG .dVisit {
    font-size: 10px;
    text-align: center;
    color: rgba(228, 18, 12, 0.74) !important;
}

.pendingBtn {
    background-color: #ea4e4a;
    border: none;
    border-radius: 4px;
    font-weight: 600;
    letter-spacing: 1px;
}

.complateBtn {
    background-color: #60d460;
    border: none;
    border-radius: 4px;
    font-weight: 600;
    letter-spacing: 1px;
    letter-spacing: 1px;
}

.cancelBtn {
    background-color: #ea56a6;
    border: none;
    border-radius: 4px;
    font-weight: 600;
    letter-spacing: 1px;
}

.realloactBtn {
    background-color: #505fea;
    border: none;
    border-radius: 4px;
    font-weight: 600;
    letter-spacing: 1px;
}