/* .dateSelect_bg {
    color: rgb(54, 54, 54);
    background-color: white;
    width: auto;
    height: 35px;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: 0;
    margin-top: -12px;
} */

/* -------------------panding button-------------------------- */
.visit-comp[_ngcontent-c5] .mat-card[_ngcontent-c5] {
    padding: 10px !important;
}

.egret-blue .mat-card:not([class*='mat-elevation-z']) {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.pending-card[_ngcontent-c5] {
    background-color: rgba(228, 18, 12, 0.74);
    color: white;
}

.egret-blue .mat-card {
    background: white;
    color: rgba(0, 0, 0, 0.87);
}

.mat-card {
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
    display: block;
    position: relative;
    padding: 16px;
    border-radius: 4px;
    overflow: hidden;
    background-color: rgba(228, 18, 12, 0.74);
    font-size: .875rem;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* -------------------------------add btn---------------------------------------------- */
.addBtn {
    font-family: inherit;
    border: none;
    border-radius: 4px;
    /* font-weight: 600; */
    letter-spacing: 1px;
    /* width: 100%; */
    height: 35px;
    background-color: #43668E;
    /* border: 1px solid #43668E; */
    font-size: 12px;
    color: white;
    /* width: 100%;
    height: 35px; */
}

.addAIBtn {
    background-color: #43668E;
    border: none;
    border-radius: 4px;
    /* font-weight: 600; */
    letter-spacing: 1px;
    width: 100%;
    height: 35px;
    padding: 0;
}

.visitSearch {
    margin-top: 10px;
}


.status-cell.completed {
    background-color: #60d460 !important;
    color: white;
    font-family: inherit;
    display: flex;
    justify-content: center;
}

.status-cell.reallocated {
    background-color: rgba(20, 41, 228, 0.74) !important;
    color: white;
    font-family: inherit;
    display: flex;
    justify-content: center;
   
}
.status-cell.cancelled {
    background-color: rgba(228, 29, 136, 0.74) !important;
    color: white;
    font-family: inherit;
    display: flex;
    justify-content: center;
    /* padding:0px 24px 0 10px */
}
.status-cell.pending{
    background-color: #ea4e4a !important;
    color: white;
    font-family: inherit;
    display: flex;
    justify-content: center;
    /* padding: 0px 24px 0 23px; */
}
.normalVisitCell{
    background-color: #60d460 !important;
    color: white;
    display: flex;
    justify-content: center;
    width: 200px;
}
.emergencyVisitCell{

    background-color: #ea4e4a !important;
    color: white;
    display: flex;
    justify-content: center;
    width: 200px;

}
.localVisitCell{
    
    background-color: rgba(228, 29, 136, 0.74) !important;
    color: white;
    display: flex;
    justify-content: center;
    width: 200px;
}
/* ------------------------------Visit_AIT-------------------------------------- */
.status-AITcell.Completed{
    background-color: #60d460 !important;
    display: flex;
    justify-content: center;
    /* padding: 0px 11px 0 11px; */
    color: white;
}
.status-AITcell.Pending{
    background-color: #ea4e4a !important;
    display: flex;
    justify-content: center;
    color: white;
}
.status-AITcell.Reallocated {
    background-color: rgba(20, 41, 228, 0.74) !important;
    display: flex;
    justify-content: center;
    color: white;
}
.status-AITcell.Cancelled {
    background-color: rgba(228, 29, 136, 0.74) !important;
    color: white;
    font-family: inherit;
    display: flex;
    justify-content: center;
}
 .normalVisitAITCell{
    background-color: #60d460 !important;
    color: white;
    display: flex;
    justify-content: center;
    width: 200px;
}
.emergencyVisitAITCell{

    background-color: #ea4e4a !important;
    color: white;
    display: flex;
    justify-content: center;
    width: 200px;

} 
.localVisitAITCell{
    
    background-color: rgba(228, 29, 136, 0.74) !important;
    color: white;
    display: flex;
    justify-content: center;
    width: 200px;
}
.VisitIDcell{
    background-color: #dfe95a;
    color: white;
    display: flex;
    justify-content: center;
    width: 200px;
}

/* -----------------------Visit_Reallocation----------------------- */
.status-Realcell.Completed{
    background-color: #60d460 !important;
    display: flex;
    justify-content: center;
    /* padding: 0px 11px 0 11px; */
    color: white;
}
.status-Realcell.Pending{
    background-color: #ea4e4a !important;
    display: flex;
    justify-content: center;
    color: white;
}
.status-Realcell.Reallocated {
    background-color: rgba(20, 41, 228, 0.74) !important;
    display: flex;
    justify-content: center;
    color: white;
}
.status-Realcell.Cancelled {
    background-color: rgba(228, 29, 136, 0.74) !important;
    color: white;
    font-family: inherit;
    display: flex;
    justify-content: center;
}

/* ------------------------Visit_Cancelation--------------------------------- */
.style-icon{
   color: #ea4e4a;
   font-size: 20px  ;
}

.status-CancelCell.Pending{
    background-color: #ea4e4a !important;
    color: white;
    font-family: inherit;
    display: flex;
    justify-content: center;
}

.status-CancelCell.Reallocated{
    background-color: rgba(20, 41, 228, 0.74) !important;
    color: white;
    font-family: inherit;
    display: flex;
    justify-content: center;
}

.status-CancelCell.Cancelled{
    background-color: rgba(228, 29, 136, 0.74) !important;
    color: white;
    font-family: inherit;
    display: flex;
    justify-content: center;

}
.status-CancelCell.Completed{
    background-color: #60d460 !important;
    color: white;
    font-family: inherit;
    display: flex;
    justify-content: center;

}



