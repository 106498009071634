.blue_bg_Animal1 {
    background-color: #FAFAFA;
    height: 78vh;
}

.actionColum {
    border: 1px solid #43668e;
    height: 75vh;
}

.mainActionColum {
    border: 1px solid #43668e;
}

.noData {
    position: absolute;
    top: 40%;
    left: 35%;
    display: flex;
}
.NoTableBorder .noData{
    position: absolute;
    top: 40%;
    left: 40%;
    display: flex;
    color:gray !important;
}

.mainColHeading {
    width: 580px !important;
}

.actionColum .guNKac {
    padding: 4px 0;
    background-color: #43668e !important;
    color: #FFFFFF !important;
    font-size: 13px !important;
}

.mainActionColum .col-xl-2 {
    width: 12.2222211% !important;
}

@media screen and (min-width:1200px) and (max-width:1400px) {
    .mainActionColum .col-xl-2 {
        width: 11.9922211% !important;
    }

    .mainColHeading {
        width: 50px !important;
    }
}