.parity-list p {
    cursor: pointer;
    font-size: 13px;
    padding: 0;
    margin: 0;
}
.mainMenuName{
    cursor:pointer !important;
}

.subName {
    cursor: move;
}

.modal-fullscreen {
    width: 90vw !important;
    max-width: none;
    margin: auto !important;
}

.modal-fullscreen .modal-content {
    height: 90% !important;
}

.accordion-button {
    font-size: 12px !important;
}

.herdHeader1 {
    position: sticky !important;
    top: -2px !important;
    vertical-align: top !important;
    background-color: #ECECEC !important;
    font-size: 24px !important;
}

.herdHeader1 th{
    font-size: 12px !important;
}

.mainTable td,
.mainTable th {
    border-width: 1px;
    text-align: start;
}

.mainTable td {
    font-size: 12px;
    cursor: pointer;
}

/* .overTable tr {
    border: 1px solid black;
} */

.customeTable {
    /* width: 40% !important; */
}

tbody,
tfoot,
th,
thead,
tr {
    border-color: lightgray ;
}

td,
th {
    text-align: end;
}

th:nth-child(-n +3),
td:nth-child(-n + 3) {
    text-align: start ;
}

/* td:nth-child(-n +3) {
    position: sticky;
    left: -5px;
    background-color: lightgray;
}

th:nth-child(-n +3) {
    position: sticky;
    left: -5px;
    background-color: lightgray;
} */

.customeTable {
    table-layout: fixed;
    width: max-content;
}


td.birthDateColumn {
    text-align: center;
}


@media screen and (max-width:1400px){
    .parity-list p{
        font-size: 10px !important;
    }
    .parity-list img{
        width:16px !important;
    }
}