.login-main-bg {
    background-color: #E9DEDE;
    min-height: 100vh;
}

.login-sub {
    background-color: #fff;
}

.login-sub h2 {
    color: #949191;
}

.login-sub p {
    color: #949191;
}

.login-sub input {
    border: 1px solid #E9DEDE;
    border-radius: 30px;
    height: 50px;
    width: 100%;
}

.login-sub input:focus {
    outline: none !important;
}

.login-sub button {
    color: white;
    font-weight: 500;
    width: 100%;
    height: 45px;
    border-radius: 30px;
    background-color: #949191;
    border: 1px solid #949191;
}

.passInput {
    position: relative;
}

.eyeImg {
    position: absolute;
    top: 12%;
    right: 5%;
}



