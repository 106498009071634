.form-field {
    display: block;
}

.form-field__label {
    font-size: 0.75rem;
    transform: translateY(-14px);
    display: block;
    font-weight: normal;
    left: 0;
    margin: 0;
    padding: 13px 10px 0;
    position: absolute;
    top: 0;
    transition: all 0.4s;
    width: 100%;
}

.form-field__control {
    background: #eee;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.form-field__bar {
    border-bottom: 2px solid black;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    transform: scaleX(0);
    transition: all 0.4s;
    width: 1%;
}

.form-field__input,
.form-field__textarea {
    appearance: none;
    background: transparent;
    border: 0;
    border-bottom: 1px solid #999;
    color: #333;
    display: block;
    font-size: 16px;
    margin-top: 15px;
    outline: 0;
    padding: 2px 5px 3px 10px;
    width: 100%;
}

.form-field__input:-ms-input-placeholder~.form-field__label,
.form-field__textarea:-ms-input-placeholder~.form-field__label {
    font-size: 1rem;
    transform: translateY(0);
}

.form-field__input:placeholder-shown~.form-field__label,
.form-field__textarea:placeholder-shown~.form-field__label {
    font-size: 0.7rem;
    transform: translateY(2px);
}

.form-field__input:focus~.form-field__label,
.form-field__textarea:focus~.form-field__label {
    color: black;
    font-size: 0.75rem;
    transform: translateY(-13px);
}

.form-field__input:focus~.form-field__bar,
.form-field__textarea:focus~.form-field__bar {
    border-bottom: 2px solid black;
    transform: scaleX(150);
}

/* mui */
.css-10botns-MuiInputBase-input-MuiFilledInput-input{
    /* padding-bottom: 0px !important; */
    padding-top: 20px !important;
}
.css-e4w4as-MuiFormLabel-root-MuiInputLabel-root{
    font-size: 14px !important;
}
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
    background-color: #EEEEEE !important;
}