.overview-card {
    background-color: #ECECEC;
    border-radius: 12px;
    min-height: 110px;
    max-height: auto;
    width: 100%;
}

.overview-card p {
    font-size: 12px !important;
}

.overview-card h5 {
    font-size: 18px;
}

.overTable {
    width: 100%;
}

.overTableheader {
    background-color: #8FA9F5;
    height: 35px;
    color: white;
    width: 100%;
    border-collapse: collapse;
    border-radius: 30px;
    border-style: hidden;
    border: 1px solid black;
}

.overTableBody:last-child {
    background-color: #ECECEC;
    position: sticky;
    bottom: -4px;
}

.overTableBody {
    margin-top: 10px !important;
    background-color: white;
}

.overTableH1 {
    color: black;
    padding: 5px;
}

.overTableH2 {
    color: #8FA9F5;
    padding: 5px;
}

.overTableH3 {
    color: #89DEBF;
    padding: 5px;
}

.overTableH4 {
    color: #F9D57A;
    padding: 5px;
}

.overTableH5 {
    color: #E77C71;
    padding: 5px;
}

.overTable-Total {
    border: 1px solid #919191;
}

.drop_value {
    font-size: 14px !important;
}

.sub-header h5 {
    font-size: 14px;
}

.overTableBody {
    font-size: 12px !important;
}

.recharts-wrapper {
    height: 310.667px !important;
}

.blue_bg h5 {
    font-size: 16px !important;
}

.recharts-text {
    font-size: 10px !important;
}

.recharts-legend-item span {
    font-size: 12px !important;
}

.dropdpwn_Open {
    display: none !important;
}

.dropdpwn_Open .menuHover:hover {
    background-color: #ECECEC;
    /* color: #FFFFFF; */
    cursor: pointer;
}

.profile_dropdown:hover .dropdpwn_Open {
    display: block !important;
    position: absolute;
    top: 40px;
    right: 0;
    width: 180%;
    height: 110px;
    padding: 20px 20px !important;
    background-color: #FFFFFF !important;
    z-index: 9999 !important;
    border-radius: 10px;
}

.bgForChart {
    background-color: #FAFAFA !important;
    border:none !important;
}

.apexcharts-toolbar {
    display: none !important;
}

.css-106c1u2-MuiBadge-badge,
.css-13xqbvb {
    top: 0px !important;
    right: 16px !important;
}

@keyframes pulseAnimation {

    0%,
    100% {
        transform: scale(0.8);
    }

    50% {
        transform: scale(1.1);
    }
}

.animated-icon {
    animation: pulseAnimation 1s infinite;
}

.passwordChange input{
    width: 100%;
    padding: 10px 20px;
}


@media screen and (max-width: 1435px) and (min-width:1400px){
    .overview-card p{
        font-size: 9px !important;
    }
  }


@media screen and (max-width: 1500px) and (min-width:1400px){
    .overview-card p{
        font-size: 9px !important;
    }
 }

  .chartBorder{
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border:1px solid rgba(0, 0, 0, 0.23);
    height: 400px;
    border-radius: 6px;
}