@keyframes loadingAnimation {
            0% {
                        opacity: 1;
            }

            50% {
                        opacity: 0.5;
            }

            100% {
                        opacity: 1;
            }
}

.loading-text {
            font-size: 20px;
            animation-name: loadingAnimation;
            animation-duration: 1.3s;
            animation-iteration-count: infinite;
}

label {
            margin-bottom: 0 !important;
}

.css-1pduc5x-MuiStack-root {
            overflow-x: hidden !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
            width: 33% !important;
}

.MainTableBorder {
            box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
            border-radius: 4px;
            padding: 2px 0.8px;
            overflow: hidden;
}

@media only screen and (max-width: 576px) {
            .mobileTable {
                        width: 100% !important;
                        margin-bottom: 10px !important;
            }
}