.blue_bg_Animal {
    background-color: #FAFAFA;
    /* height: 88vh; */
}

.btn-blue-Animal {
    background-color: #43668E;
    border: 1px solid #43668E;
    font-size: 12px;
    color: white;
    width: 100%;
    height: 35px;
}

.saveMilk {
    background-color: #43668E;
    border: 1px solid #43668E;
    font-size: 12px;
    color: white;
    width: 30%;
    height: 25px;
    border-radius: 4px;
    margin: 5px;
}