.tableDrop .css-b62m3t-container {
    width: 150% !important;
}

.tableDrop .css-13cymwt-control {
    width: 150% !important;
}

#AnimalWise .css-13cymwt-control {
    /* background-color: lightgray !important; */
}

#AnimalWise .css-1jqq78o-placeholder {
    color: #000000 !important;
}

#AnimalWise .css-1xc3v61-indicatorContainer  {
    color: #000000 !important;
}