.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
    color: black !important;
    font-size: 14px !important;
}

#drop_value:hover {
    background-color: whitesmoke !important;
}

.errorText {
    font-size: 12px;
    color: red;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    max-height: calc(50% - 32px) !important;
}

.css-13cymwt-control {
    border: none !important;
    border-radius: 0% !important;
    border-bottom: 1px solid silver !important;
    /* z-index: 9999 !important; */
}

:focus-visible {
    outline: none !important;
}

#react-select-5-listbox {
    width: 100% !important;
}

.css-b62m3t-container {
    width: 100% !important;
    font-size: 13px !important;
}

.css-1u9des2-indicatorSeparator {
    width: 0 !important;
}

.css-1nmdiq5-menu {
    z-index: 2 !important;
}

.css-1fdsijx-ValueContainer {
    padding: 0 2px !important;
}

.css-t3ipsp-controlhover {
    border-color: white !important;
}

.css-t3ipsp-control:hover {
    border-color: white !important;
}

.css-1xc3v61-indicatorContainer {
    padding: 2px !important;
}

.css-13cymwt-control:hover {
    cursor: pointer;
}

.css-1bn53lx.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: black !important;
}