@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Balinese&family=Poppins:wght@400&display=swap');

.header {
    position: sticky;
    top: 0;
    margin: 0;
    padding: 0;
    /* max-height: auto; */
    min-height: 100vh;
    /* background-color: #EAF5FF; */
    background-color: white;
    color: black;
}

.col-2, .col-lg-2 {
    padding-left: 0;
}

.sub-header1 {
    background-color: white;
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.15);
}

.sub-header1 p {
    font-size: 14px;
}

.animal-tree {
    position: sticky;
    top: 0;
    background-color: white;
    box-shadow: 2px 0px 24px rgba(0, 0, 0, 0.12);
    height: 85vh;
    overflow-y: auto;
    overflow-x: auto;
}

.animal-data {
    cursor: pointer;
}

.animal-data p {
    font-size: 9px;
    color: black;
    margin: 0;
    padding: 0;
    margin-left: 5px ;
}

.btn-blue, .btn-yellow, .btn-perot, .btn-red ,.btn-sky,.btn-oragy,.btn-purple,.btn-teal,.btn-pink,.btn-pinkre{
    
    width:100%;
    min-height: 35px;
    font-size: 12px;
    font-weight: 100;
    color: white;
}
.btn-pinkre{
    background-color: #95B9C7;
    border: 1px solid #95B9C7;
}
.btn-blue {
    background-color: #8FA9F5;
    border: 1px solid #8FA9F5;
}

.btn-yellow {
    background-color: #F9D57A;
    border: 1px solid #F9D57A;
}

.btn-perot {
    background-color: #89DEBF;
    border: 1px solid #89DEBF;
}

.btn-red {
    background-color: #E77C71;
    border: 1px solid #E77C71;
}
.btn-sky {
    background-color: #9DD9EE;
    border: 1px solid #9DD9EE;
}
.btn-oragy{
    background-color: #FEC195;
    border: 1px solid #FEC195; 
}
.btn-purple{
    background-color: #B8ACF6;
    border: 1px solid #B8ACF6; 
}
.btn-teal{
    background-color: #B3DF72;
    border: 1px solid #B3DF72; 
}
.btn-pink{
    background-color: #F797D2;
    border: 1px solid #F797D2; 
}
/* -------------------- General -------------------- */

.animal_dark_bg {
    background-color: #ECECEC;
}

.animal_dark_bg p {
    margin-bottom: 0;
}

.animal_dark_bg_text p {
    font-size: 13px;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
}

.accordion-button:not(.collapsed) {
    color: black;
    background-color: #ECECEC;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-body {
    background-color: #FAFAFA;
}

.increase_text {
    font-size: 14px;
}

.resproducion_dropdown {
    width: 100%;
    border: 1px solid #ECECEC;
    background-color: #ECECEC;
    height: 40px;
}

.animal_border {
    border: 1px solid #ECECEC;
    background-color: white;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.accordion-button {
    padding: 10px 10px 10px 20px;
    font-size: 16px;
}

/* -------------------- Parent Details -------------------- */

.blue_bg {
    background-color: #FAFAFA;
}

.dateSelect {
    color: rgb(54, 54, 54);
    background-color: #FAFAFA;
    width: 100%;
    height: 35px;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: 0;
    margin-top: -12px;
}

.dateSelect_bg {
    color: rgb(54, 54, 54);
    background-color: white;
    width: 100%;
    height: 35px;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: 0;
    margin-top: -12px;
}

.top_space {
    padding-top: 55px;
}


/* -------------------- Hide Nav Bar -------------------- */


.offcanvas {
    width: 300px !important;
}

@media screen and (min-width: 992px) {
    .hide_Navbar {
        display: none;
    }
}

.offcanvas-backdrop.show {
    opacity: 0 !important;
}

.chartText{
    font-size: 12px !important;
}