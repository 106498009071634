.profileTop h5 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.profileTop h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
}

.mainProfile {
    background-color: #FAFAFA;
    padding-top: 10px;
}

.mainProfile .Deactive {
    border: none !important;
    background-color: #E16659 !important;
}

.herd h5 {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}

.subDetails {
    background-color: #DFDFDF;
}

.herd {
    transition: .8s;
    cursor: pointer;
}

.herd .active {
    /* transition: .2s; */
    border-bottom: 3px solid #43668E;
    background: inherit !important;
}

.kYqXdJ {
    background-color: #ECECEC !important;
}

#cell-1{
    display: flex;
    justify-content: end !important;
}
