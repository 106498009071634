.date {
    font-size: 8px;
    font-weight: 800;
    letter-spacing: 1px;
}

.inpField {
    width: 120px !important;
}

.inpField1 {
    width: 39px !important;
}

.css-q8hpuo-MuiFormControl-root {
    position: relative !important;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    position: absolute !important;
    content: "" !important;
    min-height: max-content !important;
    max-height: 250px !important;
    width: 218px;
    bottom: 10% !important;
    /* top: 10% !important; */
}

.staffDropdown{
    width: 300px!important;
}

.routedropdwn{
    width : 200px!important
}