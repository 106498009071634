.herdTable-td {
    border: 1px solid #919191;
    font-size: 14px;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.herdTable-td p {
    margin: 0;
    padding: 0;
}

.herdTable-td:nth-child(1) p {
    width: 110px;
    margin: 0;
    padding: 0;
}

.herdTable-td:nth-child(2) p {
    width: 250px;
    margin: 0;
    padding: 0;
}

.herdHeader {
    position: sticky;
    top: -2px;
    vertical-align: top;
    background-color: #ECECEC;
}

.herdHeader:last-child {
    background-color: #ECECEC;
}

.modaltable {
    background-color: #ECECEC;
    border: 1px solid #919191;
    font-size: 13px;
    vertical-align: top;
}

#center {
    height: 80vh;
}

.modaltable-tr {
    border: 1px solid #919191;
    font-size: 14px;
}

.modaltable-td {
    border: 1px solid #919191;
}

.modaltable-td1 {
    border: 1px solid gray;
}

.modaltable-td p {
    margin: 0;
    width: max-content;
}


/* ---------- Loader ---------- */
.circleText {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px 0px;
    width: 130px;
    height: 130px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.progressText {
    text-align: center;
    position: absolute;
    top: 37%;
    left: 38%;
}

.loader-circle-13 {
    width: 70px;
    height: 70px;
    border: 5px solid;
    border-color: #2fa2c5 transparent;
    border-radius: 50%;
    display: inline-block;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner div {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #8FA9F5;
    animation: sk-bouncedelay 1.4s infinite;
}

.spinner .bounce1 {
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
    0% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }

    80% {
        transform: scale(0);
    }

    100% {
        transform: scale(0);
    }
}

/* table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
} */
.accordHeading {
    font-size: 13px !important;
}

.accordion-button {
    padding: 2px 10px !important;
}

.css-ahj2mt-MuiTypography-root {
    font-size: 0.6rem !important;
}

.row>* {
    padding-right: calc(var(--bs-gutter-x) * .3) !important;
    padding-left: calc(var(--bs-gutter-x) * .2) !important;
}

.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 0.8rem !important;
}

.dataHeading {
    font-size: 14px !important;
}

.dateSelect_bg {
    font-size: 13px !important;
}

.css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root {
    font-size: 0.9rem !important;
}

.kBUKwk {
    min-height: 24px !important;
}

#TableHeight .accordion-flush .accordion-collapse {
    height: 80vh !important;
}

#DateWise .recharts-wrapper {
    height: 30vh !important;
}

.mainDatewiseTable tr:last-child {
    background-color: #ECECEC !important;
    position: sticky !important;
    bottom: -2px !important;
}