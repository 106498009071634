.mapouter {
    height: 74vh !important;
}

.mapHeader {
    background-color: #FAFAFA;
    padding-bottom: 20px;
}

.leaflet-container {
    width: 100% !important;
    height: 84vh !important;
}

.mainMap {
    position: relative !important;
    z-index: 1;
}

.mapTable {
    position: absolute !important;
    top: 50%;
    left: 88% !important;
    width: 50px;
    z-index: 9999 !important;
}