.guNKac {
    background-color: #ECECEC !important;
    min-height: 35px !important;
}

/* header */

.glyQNg {
    margin: 0;
    padding-left: 5px !important;
    padding-right: 0px !important;
}

/* sub Data Row */

.eVPPvF {
    padding-left: 5px !important;
    padding-right: 0px !important;
}

/* Checkbox */

.hPQIua {
    flex: 0 0 30px !important;
    min-width: 30px !important;
}

.MHRqX {
    flex: 0 0 30px !important;
    min-width: 30px !important;
}

.table-cell-border {
    border-left: 1px solid red;
    border-right: 1px solid red
}

.kBUKwk:nth-of-type(even) {
    background-color: lightgray !important;
}

.kiFtJG:nth-of-type(even) {
    background-color: lightgray !important;
}

.djSVyo, .irUZfI {
    background-color: darkgray !important;
}

.hbotQl {
    overflow: initial !important;
    white-space: normal !important;
}

.cLrkOt {
    overflow: initial !important;
    white-space: normal !important;
}