.sidebar {
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: scroll;
    box-shadow: -2px -20px 18px rgb(120 134 183 / 16%);
    margin: 0;
    padding: 0;
    transition: .3s;
}

.hideNavbar {
    position: sticky;
    top: 0;
    height: 100vh;
}

.sidebar:hover {
    transition: .3s;
    overflow-y: scroll;
}

.sidebar::-webkit-scrollbar {
    width: 10px;
    height: 5px;
}

.sidebar::-webkit-scrollbar-thumb {
    background: #ECECEC;
    border-radius: 10px;
}

.sub-header {
    background-color: #ECECEC !important;
}

.sidebar h3 {
    color: #949191;
}

.link,
.link_sub {
    transition: all 0.5s;
    text-decoration: none;
    height: 50px;
    border-radius: 10px;
    cursor: pointer;
}

.link_text {
    font-style: normal;
    color: #949191;
    font-weight: 500;
    font-size: 13px;
}

.link_sub:hover {
    background: linear-gradient(90.16deg, #EDDADA 0.14%, rgba(242, 212, 212, 0.22) 99.89%);
    text-decoration: none !important;
}

.active {
    background: linear-gradient(90.16deg, #EDDADA 0.14%, rgba(242, 212, 212, 0.22) 99.89%);
}

.link:hover {
    background-color: #EAF5FF;
    text-decoration: none !important;
}

@media screen and (max-width: 992px) {

    .hideNavbar,
    .hideNavbar1 {
        display: none;
    }
}

.link img {
    width: 16px;
}

.link_text {
    font-size: 12px;
    color: black;
}

.mainArrow img {
    width: 12px;
    height: 12px;
}