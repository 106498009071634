.mainTabPanel .btn-blue-Animal {
    background-color: #FFFFFF !important;
    border: 1px solid #43668E !important;
    color: #43668E !important;
}

.activeLabtab {
    border: 1px solid #43668E;
    font-size: 12px;
    width: 100%;
    height: 35px;
    background-color: #43668E !important;
    color: #FFFFFF !important;
}

.leftPannel {
    background-color: #FAFAFA;
    height: 87vh !important; 
     /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important; */
     /* box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; */
}

.clrchange,
.clrchange2,
.clrchange3,
.clrchange4,
.clrchange5,
.clrchange6,
.clrchange7,
.clrchange8,
.clrchange9,
.clrchange10,
.clrchange11,
.clrchange12 {
    border: 1px solid #43668E;
    font-size: 12px;
    width: 100%;
    height: 35px;
    background-color: #579167 !important;
    color: #FFFFFF !important;
}
input:checked{
    accent-color: #43668E !important;
}

.searchWrapper{
    font-size: 14px!important;
    border: 0!important;
    border-radius: 0!important;
    padding: 0px!important;
    padding: 9px 0 5px 0!important;
}
.Multiselect{
    width: 100%;
}
.multi{
    width: 100%!important;
    position: relative;
    content: "";
    border-bottom: 1px solid #ccc!important;
}
.i-down{
    position: absolute;
    content: "";
    right: 4px!important;
    font-size: 11px;
    color: #a5a5a5;
    font-weight: 100;
    bottom: 20%;
}
.i-down:focus .SlArrowDown{
    font-size: 16px;
}
.relativedrop {
    position: relative;
}
.absSearch {
    position: absolute;
    top: 0px;
    left: 0;
    opacity: 0;
}
/* .css-fvipm8{
    width: 100%!important;
    border: none!important;
    border-radius: 0px!important;
} */
