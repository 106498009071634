#light_Btn {
    width: 100%;
    height: 40px;
    background-color: #ECECEC;
    border: 1px solid #ECECEC;
    color: black;
    font-size: 12px;
}

.SaveBtn {
    border: none;
    box-shadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)";
    background-color: #43668E;
    color: #FFFFFF;
    border-radius: 5px;
    width: 30%;
    height: 30px;
}

.backBtn{
    border: none;
    box-shadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)";
    background-color: #43668E !important;
    color: #FFFFFF !important;
    border-radius: 5px;
    width: 30%;
    height: 30px;
}