.rightTable {
    border: 2px solid #43668E;
    border-radius: 5px;
}

.boxBg {
    background-color: #ECECEC;
}

.boxBg1 {
    background-color: #F1F1F1;
}

.cardHeading h5 {
    height: 20px;
    padding: 5px 0;
}

.mainCard {
    background-color: #FAFAFA;
    height: 79vh;
}

.boxBg h6 {
    font-size: 12px;
}

.profileTop h5 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.profileTop h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
}

.mainProfile {
    background-color: #FAFAFA;
    padding-top: 10px;
}

.mainProfile .Deactive {
    border: none !important;
    background-color: #E16659 !important;
}

.herd h5 {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}

.subDetails {
    background-color: #DFDFDF;
}

.herd {
    transition: .8s;
    cursor: pointer;
}

.herd .active {
    /* transition: .2s; */
    border-bottom: 3px solid #43668E;
    background: inherit !important;
}

.kYqXdJ {
    background-color: #ECECEC !important;
}

.tab1 {
    border-bottom: 3px solid #43668E;
    cursor: pointer;
}

.calvingTab button {
    border: none;
}

.calTab {
    background-color: lightgray;
}

#addAnimal .col-xl-3 {
    width: 24% !important;
}

#imgaeAdd .cancelImage {
    position: absolute;
    top: -20%;
    left: 180%;
    z-index: 111;
}

#imgaeAdd .imageFile {
    position: relative !important;
}

.tabHeading {
    font-size: 14px;
}

@media screen and (max-width:992px) {
    .tabHeading {
        font-size: 12px;
    }
}

@media screen and (max-width: 576px) {
    .tabHeading {
        font-size: 7px !important;
    }

    #addAnimal .mainHeading {
        font-size: 10px !important;
    }

    #addAnimal .form-field__input:placeholder-shown~.form-field__label {
        font-size: 9px !important;
    }

    #addAnimal .form-field__input {
        font-size: 13px !important;
    }

    #addAnimal .mainHeading {
        font-size: 5px !important;
    }

    #addAnimal .form-field__input {
        font-size: 12px !important;
    }

    #addAnimal .calvingHead {
        font-size: 12px !important;
    }
}

#addAnimal button {
    font-size: 12px !important;
}

#addAnimal .form-field__input:placeholder-shown~.form-field__label {
    font-size: 12px !important
}

#addAnimal h6 {
    font-size: 12px !important;
}

#addAnimal .mainHeading {
    font-size: 14px !important;
}

#addAnimal .css-2m9kme-MuiFormControl-root {
    width: 250px !important;
}

.paritySection .css-1y4n74x>:not(style){
    width: 15ch !important;
}
.statusName{
    font-weight: bold;
    letter-spacing: 1px;
}
.css-14x1nlk{
    font-size: 13px !important;
}